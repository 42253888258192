<template>
<div class="">

    <span class="block text-sm font-medium text-slate-700">
        Newsletter
    </span>

    <div class="flex gap-1 mt-1 flex-wrap">

        <label class="basis-1/2 flex-auto">
            <input type="email" name="email" class="px-3 py-1 w-full bg-white border shadow-sm border-slate-300 placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-sky-500 rounded-md sm:text-sm focus:ring-1" placeholder="you@example.com" />
        </label>
        
        <button type="button" class="rounded-md flex-1 whitespace-nowrap bg-evellePrimary px-5 py-1 text-white font-medium text-sm text-center hover:bg-evellePrimaryHover">Sign Up</button>
    </div>
        
</div>

    
</template>

<script lang="ts">

export default defineNuxtComponent({
    name: 'emailSignUp',
})
</script>

<style lang="scss">

</style>